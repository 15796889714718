<template>
  <v-container fluid fill-height class="primary">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-img src="../assets/images/logo.png" max-width="160" />
          </v-col>
        </v-row>
        <v-card class="my-5">
          <v-card-text>
            <v-form @submit.prevent="onLogin">
              <v-row>
                <v-col cols="12">
                  <h2>Login de Usuário</h2>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details type="email" label="E-mail" v-model="credentials.email"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details obscore type="password" label="Senha" v-model="credentials.password"/>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed large block type="submit" color="primary" :loading="loading" :disabled="loading">
                    Entrar
                  </v-btn>
                </v-col>
                <v-col class="text-center">
                  <v-btn text>
                    Recuperar a senha!
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-btn large block text dark type="button" :to="{name: 'auth.register'}">
              Cadastrar
            </v-btn>
          </v-col>
        </v-row> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import AuthService from '../services/auth'

  export default {
    data: () => ({
      loading: false,
      credentials: {
        email: null,
        password: null
      }
    }),
    methods: {
      onLogin() {
        this.loading = true

        AuthService.login(this.credentials)
          .then((data) => {
            this.$store.dispatch('StoreAuthAction', data)
              .then(() => {
                this.$router.push({
                  name: 'home'
                })
              })
          })
          .catch((data) => {
            this.$root.$emit('snackbar', {
              top: true, color: 'red', text: data.message
            })
          })
          .then(() => this.loading = false)
      }
    }
  }
</script>
